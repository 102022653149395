@use "./imports/variables" as vars;
@use "./imports/utils";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&display=swap");

@font-face {
    font-family: "Uncertain";
    src: url("../assets/fonts/Uncertain/Uncertain-Regular.woff2") format("woff2"),
        url("../assets/fonts/Uncertain/Uncertain-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.gift-finder {
    @import "./imports/reboot";
    @import "./imports/typography";

    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    height: -webkit-fill-available;
    max-width: vars.$pop-up-max-width;

    font-family: vars.$primary-font;
    font-size: vars.$body-size;
    line-height: vars.$body-line;
    font-weight: vars.$primary-font-weight;

    display: none;

    &.is-open {
        display: block;
        z-index: 2000;
    }

    .loading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: vars.$hero-colour-1;
        font-family: vars.$campaign-font;
        font-size: vars.$h1-font-size;
    }

    .sr-only {
        @include utils.sr-only;
    }
}

.gift-guide-trigger {
    background: vars.$light-pink;
    border-radius: 50%;
    height: 30vw;
    width: 30vw;
    max-width: 200px;
    max-height: 200px;
    position: fixed;
    right: 50px;
    bottom: 50px;
    box-shadow: 0px 17px 27px 27px rgb(196 196 196 / 70%);
    cursor: pointer;
    font-family: vars.$primary-font;

    display: none;

    .gift-guide-trigger-arrow {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 26%;
        width: auto;
        transform: translate(-70%, -10%);
    }

    .gift-guide-trigger-stars-left {
        position: absolute;
        left: 0;
        top: 0;
        height: 18%;
        width: auto;
        transform: translate(-10%, 0%);
    }

    .gift-guide-trigger-stars-right {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 18%;
        width: auto;
        transform: translate(45%, -55%);
    }

    .gift-guide-trigger-image {
        background-image: url("https://www.datocms-assets.com/56421/1649210182-mirvac_mothersday_4.png?h=200&w=200&fit=crop&crop=focalpoint");
        @include utils.background-image();
        display: block;
        border-radius: 50%;
        margin: 4%;
        height: 92%;
    }

    .gift-guide-trigger-button {
        font-size: 3vw;
        line-height: 1;
        font-weight: 500;
        color: black;
        border-radius: 18px;
        background: vars.$hero-colour-1;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        padding: 1.5vw;
        text-align: center;
        min-width: 100%;
        white-space: nowrap;
    }
}

@media screen and (min-width: 455px) {
    .gift-finder {
        top: 30px;
        right: 30px;
        width: calc(100vw - 60px);
        height: calc(100vh - 60px);
    }

    .gift-guide-trigger {
        .gift-guide-trigger-button {
            font-size: 16px;
            min-width: 169px;
            padding: 10px;
        }
    }
}
