$primary-font: "Open Sans", sans-serif;
$campaign-font: "Uncertain", sans-serif;
$primary-font-weight: 400;
$body-size: 16px;
$body-line: 1.5;

$black: black;
$green: #86c796;
$dark-red: #cd4a4c;
$red: #e55355;
$lighter-red: #e76567;
$light-red: #ff9495;
$orange: #e69e68;
$cream: #ffffeb;
$brown: #695151;
$light-grey: #fcf7f7;
$grey: #e5e5e5;
$darker-grey: #9B9B9B;
$blue-grey: #475569;
$neutral-grey: #64748B;
$light-blue-grey: #E2E8F0;



// Mothers day colours // TODO: remove the other ones if not used?
$light-grey: #EEF3F4;
$grey: #e5e5e5;
$blue: #B1C7CD; 
$pink: #E79BB4;
$orange: #F6C093;
$light-pink: #FCF7F7;
$yellow: #FCEFD9;

$hero-colour-1: $blue;
$hero-colour-2: $pink;
$hero-colour-3: $orange;
$background-colour: $light-grey;

// Stuff for the reboot
$sub-sup-font-size: 0.75em !default;
$link-color: black !default;
$link-decoration: underline !default;
$link-shade-percentage: 20% !default;
$link-hover-color: black !default;
$link-hover-decoration: null !default;

$spacer: 1.25rem !default; // 20px
$headings-margin-bottom: $spacer !default;
$headings-font-family: $primary-font;
$headings-font-style: null !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default;
$headings-color: null !default;

$font-size-base: 16px; //1rem !default; // Assumes the browser default, typically `16px`
$h1-font-size: $font-size-base * 1.625 !default; // 26px
$h2-font-size: $font-size-base * 1.125 !default; // 18px ....11.25 ... base is 62.5% = 10px
$h3-font-size: $font-size-base !default;

$h1-line-height: 1;
$h2-line-height: 1.11;
$h3-line-height: 1.25;

$paragraph-margin-bottom: 1rem !default;
$font-weight-bolder: bolder !default;
$small-font-size: 0.875em !default; // 14px

$container-padding: 15px;
$pop-up-max-width: 455px;