@use "./variables" as vars;

*,
*::before,
*::after {
  box-sizing: border-box;
}

// Sub and Sup
//
// Prevent `sub` and `sup` elements from affecting the line height in
// all browsers.

sub,
sup {
  position: relative;
  font-size: vars.$sub-sup-font-size;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}

// Links

a {
  color: vars.$link-color;
  text-decoration: vars.$link-decoration;

  &:hover {
    color: vars.$link-hover-color;
    text-decoration: vars.$link-hover-decoration;
  }
}

// And undo these styles for placeholder links/named anchors (without href).
// It would be more straightforward to just use a[href] in previous block, but that
// causes specificity issues in many other styles that are too complex to fix.
// See https://github.com/twbs/bootstrap/issues/19402

a:not([href]):not([class]) {
  &,
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

// Figures
//
// Apply a consistent margin strategy (matches our type styles).

figure {
  margin: 0 0 1rem;
}

// Images and content

img,
svg {
  vertical-align: middle;
}

// Forms
//
// 1. Allow labels to use `margin` for spacing.

label {
  display: inline-block; // 1
}

// Explicitly remove focus outline in Chromium when it shouldn't be
// visible (e.g. as result of mouse click or touch tap). It already
// should be doing this automatically, but seems to currently be
// confused and applies its very visible two-tone outline anyway.

button:focus:not(:focus-visible) {
  outline: 0;
}

// 1. Remove the margin in Firefox and Safari

input,
button,
select,
optgroup,
textarea {
  margin: 0; // 1
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

// Remove the inheritance of text transform in Firefox
button,
select {
  text-transform: none;
}
// Set the cursor for non-`<button>` buttons
//
// Details at https://github.com/twbs/bootstrap/pull/30562
[role="button"] {
  cursor: pointer;
}

select {
  // Remove the inheritance of word-wrap in Safari.
  // See https://github.com/twbs/bootstrap/issues/24990
  word-wrap: normal;

  // Undo the opacity change from Chrome
  &:disabled {
    opacity: 1;
  }
}

// Remove the dropdown arrow in Chrome from inputs built with datalists.
// See https://stackoverflow.com/a/54997118

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS and Safari.
// 3. Opinionated: add "hand" cursor to non-disabled button elements.

button,
[type="button"], // 1
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; // 2

  &:not(:disabled) {
    cursor: pointer; // 3
  }
}

// Remove inner border and padding from Firefox, but don't restore the outline like Normalize.

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

// 1. Textareas should really only resize vertically so they don't break their (horizontal) containers.

textarea {
  resize: vertical; // 1
}

// Fix height of inputs with a type of datetime-local, date, month, week, or time
// See https://github.com/twbs/bootstrap/issues/18842

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

// 1. Correct the outline style in Safari.
// 2. This overrides the extra rounded corners on search inputs in iOS so that our
//    `.form-control` class can properly style them. Note that this cannot simply
//    be added to `.form-control` as it's not specific enough. For details, see
//    https://github.com/twbs/bootstrap/issues/11586.

[type="search"] {
  outline-offset: -2px; // 1
  -webkit-appearance: textfield; // 2
}

// Remove the inner padding in Chrome and Safari on macOS.

::-webkit-search-decoration {
  -webkit-appearance: none;
}

// Hidden attribute
//
// Always hide an element with the `hidden` HTML attribute.

[hidden] {
  display: none !important;
}
