@use "sass:math";

@function rem($size) {
    $remSize: math.div($size, 16);
    @return #{$remSize}rem;
}

@mixin background-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

@mixin sr-only {
    border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
    white-space: nowrap !important; 
}