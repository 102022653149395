@use "./variables" as vars;

input {
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
}

button {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5; //24px;
}

// Typography
//
// 1. Remove top margins from headings
//    By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
//    margin for easier control within type scales as it avoids margin collapsing.

%heading {
    margin-top: 0; // 1
    margin-bottom: vars.$headings-margin-bottom;
    font-family: vars.$headings-font-family;
    font-style: vars.$headings-font-style;
    font-weight: vars.$headings-font-weight;
    line-height: vars.$headings-line-height;
    color: vars.$headings-color;
}

h1 {
    @extend %heading;
    font-family: vars.$campaign-font;
    font-weight: 400;
    font-size: vars.$h1-font-size;
    line-height: vars.$h1-line-height;
}

h2 {
    @extend %heading; 
    font-size: vars.$h2-font-size;
    line-height: vars.$h2-line-height;

}

h3 {
    @extend %heading;
    font-size: vars.$h3-font-size;
    line-height: vars.$h3-line-height;

}


// Reset margins on paragraphs
//
// Similarly, the top margin on `<p>`s get reset. However, we also reset the
// bottom margin to use `rem` units instead of `em`.

p {
    margin-top: 0;
    margin-bottom: vars.$paragraph-margin-bottom;
}

// Lists

ol,
ul {
    padding-left: 2rem;
}

ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

// Strong
//
// Add the correct font weight in Chrome, Edge, and Safari

b,
strong {
    font-weight: vars.$font-weight-bolder;
}

// Small
//
// Add the correct font size in all browsers

small {
    font-size: vars.$small-font-size;
}
